import React from 'react';

function ToTopButton() {
    const handleToTop = evt => {
        evt.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return <i className="uk-icon-button uk-button-primary uk-box-shadow-large" data-uk-icon="chevron-up" onClick={handleToTop} />;
}

function ToTop() {
    return (
        <div className="uk-position-bottom-left uk-position-bottom-right uk-position-fixed" style={{zIndex: 1000}}>
            <div className="uk-container uk-padding-remove uk-background-primary" style={{ height: 0 }}>
                <div className="uk-position-relative">
                    <div className="uk-hidden@xl uk-inline-block uk-position-bottom-right uk-position-small">
                        <ToTopButton />
                    </div>
                    <div className="uk-visible@xl uk-inline-block uk-width-1-1">
                        <div
                            className="uk-position-center-right-out uk-position-bottom uk-position-large"
                            // uikit provides only centered outline position classes (uk-position-center-right-out)
                            // this reset centered position
                            style={{ transform: 'translateY(0)', top: 'unset' }}
                        >
                            <ToTopButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToTop;
