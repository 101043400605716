import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import account from './account';
import auth from './auth';
import carBrands from './carBrands';
import filter from './filter';
import floatingSidebar from './floatingSidebar';
import hosts from './hosts';
import notFound from './notFound';
import oneTrust from './oneTrust';
import optionsSidebar from './optionsSidebar';
import rimProducerLogos from './rimProducerLogos';
import search from './search';
import standort from './standort';
import tires from './tires';
import UIkit from './UIkit';
import vehicle from './vehicle';
import vHostRequestLocation from './vHostRequestLocation';
import additionalCrumbs from './additionalCrumbs';
import couponHeight from './couponBannerHeight';
import vehicleidentification from './vehicleidentification';
import serviceNavigator from './../reducers/standort/service/serviceNavigator';
import serviceAngebote from './serviceArbeitswerte';
import productId from './productID';
import scrollToViewport from './scrollToViewport';
import merkliste from './merkliste';

const createRootReducer = history =>
    combineReducers({
        account,
        auth,
        carBrands,
        filter,
        floatingSidebar,
        hosts,
        notFound,
        oneTrust,
        optionsSidebar,
        rimProducerLogos,
        search,
        standort,
        tires,
        UIkit,
        vehicle,
        vHostRequestLocation,
        additionalCrumbs,
        couponHeight,
        productId,
        vehicleidentification,
        serviceNavigator,
        serviceAngebote,
        scrollToViewport,
        router: connectRouter(history),
        form: formReducer,
        merkliste,
    });

export default createRootReducer;
