import { createSelector } from 'reselect';
import { pendingResponse } from '../fetchStatus';

const merklisteSelector = state => state.merkliste.merkliste
const merklisteProductSelector = state => state.merkliste.merklistenProducts;

export const merklistenResponseSelector = createSelector([merklisteSelector], (merkliste) => { 
    return merkliste});
export const merklistenSelector = createSelector([merklistenResponseSelector], merkliste => {
    return merkliste?.data
});

export const merklistenProductResponseSelector = createSelector([merklisteProductSelector], (merklistenProducts) => {
    return merklistenProducts?.data ? merklistenProducts : pendingResponse});

export const merklistenProductSelector = createSelector([merklisteProductSelector], (merklistenProducts) => {
    return merklistenProducts?.data});
