import { isClient } from './SSRHelper';

// TODO: use everywhere

/**
 * Show a toast notification.
 * @param err - Optional error which caused the notification.
 * @param message
 * @param {"primary"|"success"|"warning"|"danger"|"default"} status
 */
export function toast({ err, message = 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.', status = 'danger' } = {}) {
    if (isClient) {
        if (err) {
            console.error('Toast error:', err, 'Toast:', { message, status });
        } 
        if (status === 'default') {
            status = undefined;
        }
        window.UIkit?.notification?.(message, { pos: 'top-right', status });
    } else {
        console.warn('Tried to show toast on the server:', { err, message, status });
    }
}
