import { FETCH_MERKLISTEN_PRODUCTS_SUCCESS, FETCH_MERKLISTEN_PRODUCTS_FAILURE, ADD_TO_MERKLISTE_SUCCESS, ADD_TO_MERKLISTE_FAILURE, REMOVE_FROM_MERKLISTE_FAILURE, REMOVE_FROM_MERKLISTE_SUCCESS, ADD_TO_MERKLISTE_REQUEST, REMOVE_FROM_MERKLISTE_REQUEST, UPDATE_MERKLISTE_FOR_USER } from '../actionTypes';
import { FAILURE, FETCHING, PENDING, SUCCESS } from '../fetchStatus';

const initialState = {
    merkliste: {
        data: [],
    },
    merklistenProducts: {}
};

export default function merkliste(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_MERKLISTE_SUCCESS:
            let filteredProductsToAdd = [];
            if (state.merklistenProducts.data) {
                filteredProductsToAdd = state.merklistenProducts.data.filter(product => {
                    const isVariantIdMatch = product.selectedVariantId && product.selectedVariantId !== action.payload.entry.selectedVariantId;
                    const isNeutralKeyMatch = product.neutralKey && product.neutralKey !== action.payload.entry.productId;
                    const shouldIncludeProduct = isVariantIdMatch || isNeutralKeyMatch;

                    return shouldIncludeProduct;
                });
            }

            return {
                ...state,
                merkliste: {
                    status: SUCCESS,
                    data: [...(state?.merkliste?.data ?? []), { ...action.payload.entry }],
                },
                merklistenProducts: {
                    status: SUCCESS,
                    data: [...filteredProductsToAdd, { ...action.payload.product }]
                },
            };
        case REMOVE_FROM_MERKLISTE_SUCCESS:

            const filteredProductsToRemove = state.merklistenProducts.data.filter(product => {
                const isVariantIdMatch = product.selectedVariantId === action.payload.entry.productId;
                const isNeutralKeyMatch = product.neutralKey && product.neutralKey === action.payload.entry.productId;

                const shouldIncludeProduct = !(isVariantIdMatch || isNeutralKeyMatch);

                return shouldIncludeProduct;
            });

            return {
                ...state,
                merkliste: {
                    status: SUCCESS,
                    data: state.merkliste.data.filter(product => product.productId !== action.payload.entry.productId || (product.productId === action.payload.entry.productId && product.standort !== action.payload.entry.standort))
                },
                merklistenProducts: {
                    status: SUCCESS,
                    data: filteredProductsToRemove
                }
            }
        case FETCH_MERKLISTEN_PRODUCTS_SUCCESS:
            return {
                ...state,
                merklistenProducts: {
                    status: SUCCESS,
                    data: action.payload.merklistenProducts,
                }
            };
        case FETCH_MERKLISTEN_PRODUCTS_FAILURE:
            return {
                ...state,
                merklistenProducts: {
                    status: FAILURE,
                    error: action.payload.error,
                }
            };
        case ADD_TO_MERKLISTE_REQUEST:
        case REMOVE_FROM_MERKLISTE_REQUEST:
            return {
                ...state,
                merkliste: {
                    status: FETCHING,
                }
            };
        case ADD_TO_MERKLISTE_FAILURE:
        case REMOVE_FROM_MERKLISTE_FAILURE:
            return {
                ...state,
                merkliste: {
                    status: FAILURE,
                    error: action.payload.error,
                }
            };
        case UPDATE_MERKLISTE_FOR_USER:
            return {
                ...state,
                merkliste: {
                    status: SUCCESS,
                    data: action.payload.merkliste
                },
                merklistenProducts: {
                    status: PENDING,
                    data: [],
                }
            }
        default:
            return state;
    }
}
