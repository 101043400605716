// This file should only ever be imported by the client side code.
// The server creates its own store by calling createStore for each SSR request.

// Previously, this code was inlined directly in client.js
// This broke webpack HMR, since a update would trigger a recreation of the redux store *and* redux history.
// After the HMR update, there where multiple histories and client side routing was broken.
// Now that this is a module, webpack can reuse the existing store and history on the client.
// After a code change (expect this module and client.js), routing and already fetched data via redux should be preserved.
import createStore from './createStore';
import { setAuthToken } from './actions/auth/token';
import { setSessionId } from './actions/auth/sessionId';
import { loadUIkitSuccess } from './actions/UIkit';
import { updateMerklisteFromlocalstorage } from './actions/merkliste';

export const { store, history } = createStore();

import(/* webpackPreload: true, webpackChunkName: "preload" */ '../helpers/webpack/clientPreload')
    .then(() => {
        store.dispatch(loadUIkitSuccess());
    })
    .catch(console.error);

store.dispatch(setAuthToken(localStorage.getItem('token')));
store.dispatch(setSessionId(localStorage.getItem('sessionId')));
store.dispatch(updateMerklisteFromlocalstorage(localStorage.getItem('merkliste') || []));
