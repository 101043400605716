import _ from 'lodash';
import { apiClient } from '../../helpers/apiHelperV2';
import { ADD_TO_MERKLISTE_FAILURE, ADD_TO_MERKLISTE_SUCCESS, FETCH_MERKLISTEN_PRODUCTS_FAILURE, FETCH_MERKLISTEN_PRODUCTS_REQUEST, FETCH_MERKLISTEN_PRODUCTS_SUCCESS, REMOVE_FROM_MERKLISTE_FAILURE, REMOVE_FROM_MERKLISTE_REQUEST, REMOVE_FROM_MERKLISTE_SUCCESS, UPDATE_MERKLISTE_FOR_USER } from '../actionTypes';
import { shouldFetch } from '../fetchStatus';
import { userSelector } from '../selectors/auth';
import { merklistenProductResponseSelector, merklistenResponseSelector, merklistenSelector } from '../selectors/merkliste';
import { markenStandortSelector } from '../selectors/standort/markenStandort';
import { updateUser } from './user';

const isLocalStorageAvailable = () => {
    try {
        const testKey = '__test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

const safeGetLocalStorage = (key) => {
    if (typeof window === 'undefined' || !isLocalStorageAvailable()) {
        return null;
    }
    try {
        const item = localStorage.getItem(key);
        try {
            return JSON.parse(item);
        } catch {
            return item;
        }
    } catch (e) {
        return null;
    }
};

const safeSetLocalStorage = (key, value) => {
    if (!isLocalStorageAvailable()) {
        return false;
    }
    try {
        const valueToStore = typeof value === 'object' ? JSON.stringify(value) : value;
        localStorage.setItem(key, valueToStore);
        return true;
    } catch (e) {
        console.warn('Error setting localStorage:', e);
        return false;
    }
};


export const addToMerkliste = (entry) => {
    return async function (dispatch, getState) {
        console.log('entry', entry);
        let state = getState()
        const merklistenResponse = merklistenSelector(state)
        const userResponse = userSelector(state)
        const markenStandort = markenStandortSelector(state)

        let url = `/api/v1/standorte/${markenStandort.url}/categories/${entry.categoryUrl}/products/${entry.productId}`;

        if (entry.categoryUrl === "reifen") {
            url = `/api/v1/standorte/${markenStandort.url}/tires/${entry.productId}`
        }

        try {
            let { data } = await apiClient.get(url);

            if (data?.product) {
                data.product.selectedVariantId = entry.selectedVariant;

                safeSetLocalStorage('merkliste', JSON.stringify([...new Set([...merklistenResponse, { ...entry }])]));
                if (userResponse) {
                    dispatch(updateUser({ user: { ...userResponse, merkliste: [...new Set([...merklistenResponse, { ...entry }])] } }))
                }
                dispatch({
                    type: ADD_TO_MERKLISTE_SUCCESS,
                    payload: { entry, product: data.product },
                })
                return true
            }


            if (data?.tire) {
                if (userResponse) {
                    dispatch(updateUser({ user: { ...userResponse, merkliste: [...new Set([...merklistenResponse, { ...entry }])] } }))
                }
                dispatch({
                    type: ADD_TO_MERKLISTE_SUCCESS,
                    payload: { entry, product: data.tire },
                })
                return true
            }
        } catch (err) {
            dispatch({
                type: ADD_TO_MERKLISTE_FAILURE,
                payload: { err },
            })
        }
    }
};

export const updateMerklisteFromlocalstorage = (merkliste) => {
    return async function (dispatch) {
        try {
            dispatch({
                type: UPDATE_MERKLISTE_FOR_USER,
                payload: {
                    merkliste: JSON.parse(merkliste),
                },
            });
        } catch (err) {
            dispatch({
                type: 'UPDATE_MERKLISTE_FOR_USER_FAILURE',
                payload: { err },
            })
        }
    };
};

export const removeFromMerkliste = (entry) => {
    return async function (dispatch, getState) {
        let state = getState()
        const merklistenResponse = merklistenSelector(state)
        const userResponse = userSelector(state)
        const markenStandort = markenStandortSelector(state)
        console.log('merklistenResponse', merklistenResponse);
        console.log('markenStandort._id', markenStandort._id);
        console.log('entry', entry);
        try {
            let filteredMerkliste = merklistenResponse.filter(merklistenEntry => merklistenEntry?.standort !== markenStandort._id || merklistenEntry.selectedVariant !== entry.selectedVariant)
            safeSetLocalStorage('merkliste', JSON.stringify(filteredMerkliste));
            if (userResponse) {
                console.log('filteredMerkliste', filteredMerkliste);
                dispatch(updateUser({ user: { ...userResponse, merkliste: filteredMerkliste } }))
            }
            dispatch({
                type: REMOVE_FROM_MERKLISTE_SUCCESS,
                payload: { entry },
            })
            return true
        } catch (err) {
            dispatch({
                type: REMOVE_FROM_MERKLISTE_FAILURE,
                payload: { err },
            })
        }

    };
};

export const merklisteHasDifferences = (stateMerkliste, userMerkliste) => {
    let stateList = stateMerkliste?.map(entry => entry.selectedVariant).sort()
    let userList = userMerkliste?.map(entry => entry.selectedVariant).sort()
    return !_.isEqual(stateList, userList)
}

export const fetchMerklistenProductsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();
        const merklistenResponse = merklistenResponseSelector(state);
        const merklistenProductResponse = merklistenProductResponseSelector(state);
        const markenStandort = markenStandortSelector(state);
        const userResponse = userSelector(state);
        let newMerkliste = [];

        const storedMerkliste = safeGetLocalStorage('merkliste') || [];
        
        if (
            (!_.isNil(userResponse?.merkliste) || !_.isEmpty(userResponse?.merkliste)) &&
            (_.isNil(userResponse?.merkliste) || _.isEmpty(merklistenResponse.data)) &&
            merklisteHasDifferences(merklistenResponse.data, userResponse?.merkliste)
        ) {
            newMerkliste = _.unionBy(
                !_.isEmpty(merklistenResponse.data) ? merklistenResponse.data : !_.isEmpty(storedMerkliste) ? storedMerkliste : [],
                userResponse?.merkliste || [],
                "selectedVariant"
            );

            dispatch({
                type: UPDATE_MERKLISTE_FOR_USER,
                payload: {
                    merkliste: newMerkliste
                },
            });

            dispatch(updateUser({
                user: {
                    ...userResponse,
                    merkliste: newMerkliste
                }
            }));

            //safeSetLocalStorage('merkliste', newMerkliste);
        }
        if (shouldFetch(merklistenProductResponse.status) && (merklistenResponse.data?.length > 0 || storedMerkliste?.length > 0)) {
            dispatch({
                type: FETCH_MERKLISTEN_PRODUCTS_REQUEST,
                payload: {
                    markenStandortUrl: markenStandort.url,
                    merkliste: !_.isEmpty(merklistenResponse.data) ? merklistenResponse.data : !_.isEmpty(storedMerkliste) ? storedMerkliste : []
                },
            });

            try {
                const res = await apiClient.post(
                    `/api/v1/standorte/${markenStandort.url}/merkliste/products`,
                    {
                        merkliste: merklistenResponse.data
                    }
                );

                dispatch({
                    type: FETCH_MERKLISTEN_PRODUCTS_SUCCESS,
                    payload: {
                        merklistenProducts: res.data
                    },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_MERKLISTEN_PRODUCTS_FAILURE,
                    payload: { error },
                });
            }
        }
    };
};
